import React from "react";
import { Autocomplete, CircularProgress } from "@mui/material";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const FractAutoComplete = ({
  options = [],
  selectedValue = "",
  onChange,
  label = "label",
  error,
  textLoading = false,
  loading = false,
  required = false,
  dataTestId = "",
  disabled = false,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    listbox: {
      border: "1px solid #00A3E0",
      borderTop: "none",
      padding: 0,
    },
  }));

  const classes = useStyles();

  return (
    <Autocomplete
      {...rest}
      options={options || []}
      value={selectedValue}
      onChange={onChange}
      name={label}
      disabled={disabled}
      loading={textLoading}
      ListboxProps={{
        className: classes.listbox,
      }}
      renderInput={(params) => (
        <AutoCompleteCustomRender
          label={label}
          required={required}
          data-testid={dataTestId}
          error={Boolean(error)}
          helperText={error}
          disabled={disabled}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{ marginTop: "-20px" }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  filledRoot: {
    height: "38px",
    backgroundColor: `${theme.palette.color.white} !important`,
    border: "1px solid #18365073",
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
    borderRadius: "4px",
    paddingTop: "14px !important",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.color.white,
      border: `1px solid ${theme.palette.color.primary}`,
    },
  },
  disabledFilledRoot: {
    height: "38px",
    border: `1px solid ${theme.palette.color.grayS7}`,
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
    borderRadius: "4px",
    paddingTop: "14px !important",
    backgroundColor: theme.palette.color.grayS6,
    opacity: 1,
  },
  errorFilledRoot: {
    height: "38px",
    backgroundColor: theme.palette.color.white,
    border: `1px solid ${theme.palette.color.error}`,
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
    borderRadius: "6px",
    paddingTop: "14px !important",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.color.white,
    },
  },
  filledInput: {
    color: theme.palette.color.cyanBlueC1,
    ...theme.typography.body2,
    marginTop: "-2px",
  },
  underline: {
    "&:after": {
      borderBottom: "none !important",
    },
  },
  filledFocused: {
    border: `1px solid ${theme.palette.color.primary} !important`,
    borderRadius: "4px !important",
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
  },
  error: {
    border: `1px solid ${theme.palette.color.error} !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  labelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#9E9E9E",
    marginTop: "-6px",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      marginTop: "0.1px",
    },
    "& .MuiFormLabel-asterisk": {
      color: theme.palette.color.error,
    },
    "&:hover": {
      color: "#9E9E9E",
      letterSpacing: "0px",
    },
  },
  errorlabelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: theme.palette.color.error,
    marginTop: "-6px",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      marginTop: "0.1px",
    },
    "& .MuiFormLabel-asterisk": {
      color: `${theme.palette.color.error} !important`,
    },
    "&:hover": {
      color: `${theme.palette.color.error} !important`,
      letterSpacing: "0px",
    },
  },
  labelFocused: {
    ...theme.typography.small,
    color: "#9E9E9E !important",
    opacity: 1,
    marginTop: "0.1px",
  },
  labelFocusedError: {
    ...theme.typography.small,
    color: `${theme.palette.color.error} !important`,
  },
  helperTextRoot: {
    color: theme.palette.color.error,
    textTransform: "none !important",
    ...theme.typography.small,
  },
}));

const AutoCompleteCustomRender = ({ error, disabled, ...rest }) => {
  const classes = useStyles();
  let rootClass = classes.filledRoot;
  if (error) {
    rootClass = classes.errorFilledRoot;
  } else if (disabled) {
    rootClass = classes.disabledFilledRoot;
  }
  return (
    <TextField
      variant="filled"
      disabled={disabled}
      {...rest}
      fullWidth
      size="small"
      autoComplete="off"
      InputProps={{
        ...rest?.InputProps,
        classes: {
          root: rootClass,
          input: classes.filledInput,
          focused: error ? classes.error : classes.filledFocused,
          underline: classes.underline,
        },

        disableUnderline: true,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
      InputLabelProps={{
        classes: {
          root: error ? classes.errorlabelRoot : classes.labelRoot,
          focused: error ? classes.labelFocusedError : classes.labelFocused,
        },
      }}
      sx={{ marginTop: "5px", marginBottom: "-5px" }}
    />
  );
};

export default FractAutoComplete;
