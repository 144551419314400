import {
  GET_USERS_DETAILS_DATA_SUCCESS,
  GET_USERS_DETAILS_DATA_FAILURE,
  SET_LOADING,
  RESET_USERS_DETAILS,
  RESET_USERS_DETAILS_SUCCESS,
  RESET_USERS_DETAILS_FAILURE,
  GET_ATTRIBUTE_LIST_SUCCESS,
  GET_ATTRIBUTE_LIST_FAILURE,
} from "./actionTypes";

const initialState = {
  userDetails: {
    id: "",
    displayName: "",
    firstName: "",
    lastName: "",
    email: "",
    organizationId: "",
    parentOrganizationId: "",
    organizationName: "",
    isActive: "",
    isInternalUser: "",
    organizationData: [],
    role: [],
    permissions: null,
  },
  attributeList: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const EditUserDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    case GET_USERS_DETAILS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case RESET_USERS_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case RESET_USERS_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: {
          id: "",
          displayName: "",
          firstName: "",
          lastName: "",
          email: "",
          organizationId: "",
          parentOrganizationId: "",
          organizationName: "",
          isActive: "",
          isInternalUser: "",
          organizationData: [],
          role: [],
          permissions: null,
        },
      };
    }

    case RESET_USERS_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    }
    case GET_ATTRIBUTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        attributeList: action.payload,
      };
    case GET_ATTRIBUTE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default EditUserDetails;
