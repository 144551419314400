export const SET_LOADING = "SET_LOADING";
export const GET_USERS_DETAILS_DATA = "GET_USERS_DETAILS_DATA";
export const GET_USERS_DETAILS_DATA_SUCCESS = "GET_USERS_DETAILS_DATA_SUCCESS";
export const GET_USERS_DETAILS_DATA_FAILURE = "GET_USERS_DETAILS_DATA_FAILURE";

export const RESET_USERS_DETAILS = "RESET_USERS_DETAILS";
export const RESET_USERS_DETAILS_SUCCESS = "RESET_USERS_DETAILS_SUCCESS";
export const RESET_USERS_DETAILS_FAILURE = "RESET_USERS_DETAILS_FAILURE";

export const GET_ATTRIBUTE_LIST = "GET_ATTRIBUTE_LIST";
export const GET_ATTRIBUTE_LIST_SUCCESS = "GET_ATTRIBUTE_LIST_SUCCESS";
export const GET_ATTRIBUTE_LIST_FAILURE = "GET_ATTRIBUTE_LIST_FAILURE";