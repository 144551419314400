import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";

import {
  GET_ANALYTICS_VISIBILITY,
  GET_ANALYTICS_VISIBILITY_SUCCESS,
} from "./actionType";

function* getAnalyticsDetailSaga(params) {
  try {
    const URL = params.orgId
      ? `/1/users/me/menu/analytics/visibility?organizationId=${params?.orgId}`
      : "/1/users/me/menu/analytics/visibility";
    const { data } = yield API.get(URL);
    yield put({ type: GET_ANALYTICS_VISIBILITY_SUCCESS, payload: data });
  } catch (error) {
    console.log("error", error);
  }
}

function* AnalyticsSaga() {
  yield all([
    yield takeEvery(GET_ANALYTICS_VISIBILITY, getAnalyticsDetailSaga),
  ]);
}

export default AnalyticsSaga;
