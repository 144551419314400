import * as React from "react";
import Grid from "@mui/material/Grid";
import StatusCard from "./widgets/statusCard";
import OriginDelay from "../../assets/images/origindelay.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../utils/axios";
import { useEffect } from "react";
import { getDeliveryCompliance } from "../../redux/widgets/action";

export default function DeliveryCompliance() {
  let cardData = [
    {
      statusIcon: OriginDelay,
      label: "Delivery Compliance %",
      count: 0,
      type: "deliverycompliance",
    },
  ];
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [statusData, setStatusData] = React.useState(cardData);
  const navigate = useNavigate();
  const { deliveryCompliance } = useSelector((state) => state.WidgetsReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (organizationId) dispatch(getDeliveryCompliance(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (deliveryCompliance >= 0) {
      const data = [...cardData];
      data[0].count = deliveryCompliance;
      setStatusData([...data]);
    }
  }, [deliveryCompliance]);

  const redirectToOrders = (card) => {
    const gridId = "POSummary";
    localStorage.removeItem(`${gridId}-viewName`);
    localStorage.removeItem(`grid-${gridId}`);
    localStorage.removeItem(`grid-${gridId}-colums`);
    localStorage.removeItem(`${gridId}-status-card`);
    navigate(`/containersummary/live/${card.type}`);
  };

  return (
    <Grid container spacing={2}>
      {statusData?.map((card, index) => (
        <Grid
          item
          xs={6}
          key={index}
          style={{
            height: statusData.length <= 2 ? "100%" : "50%",
            width: "100",
          }}
          onClick={() => {
            redirectToOrders(card);
          }}
        >
          <StatusCard
            statusIcon={card.statusIcon}
            statusText={card.label}
            count={card.count}
          />
        </Grid>
      ))}
    </Grid>
  );
}
