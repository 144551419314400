import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_ORGANIZATION_DETAIL,
  GET_ORGANIZATION_DETAIL_FAILURE,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  GET_RESOURCE_MENU,
  GET_RESOURCE_MENU_FAILURE,
  GET_RESOURCE_MENU_SUCCESS,
  GET_ORG_RESOURCES,
  GET_ORG_RESOURCES_FAILURE,
  GET_ORG_RESOURCES_SUCCESS,
  RESET_ORGANIZATION_DETAIL,
  RESET_ORGANIZATION_DETAIL_FAILURE,
  RESET_ORGANIZATION_DETAIL_SUCCESS,
  SET_EDITORGANISATION_LOADING,
  GET_EMAIL_TYPE_SUCCESS,
  GET_EMAIL_TYPE_FAILURE,
  GET_EMAIL_TYPE,
  GET_ORGANIZATION_LIST_FAILURE,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_ORGANIZATION_LIST,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  GET_LOCAL_USER_SUCCESS,
  GET_LOCAL_USER_FAILURE,
  GET_LOCAL_USER,
  GET_ADMIN_USER,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_FAILURE,
  GET_USER_ROLE_LIST,
  GET_ACTIVE_ORG_LIST_SUCCESS,
  GET_ACTIVE_ORG_LIST_FAILURE,
  GET_ACTIVE_ORG_LIST,
  GET_HISTORY_LOG_SUCCESS,
  GET_HISTORY_LOG_FAILURE,
  GET_HISTORY_LOG,
  GET_DATE_FORMAT_SUCCESS,
  GET_DATE_FORMAT_FAILURE,
  GET_DATE_FORMAT,
  GET_ENABLE_USER_LIST_SUCCESS,
  GET_ENABLE_USER_LIST_FAILURE,
  GET_ENABLE_USER_LIST,
  SET_PERMISSION_LOADING,
  GET_DEFAULT_ROLE,
  GET_DEFAULT_ROLE_SUCCESS,
  GET_DEFAULT_ROLE_FAILURE,
} from "./actionTypes";

// api/{v}/Shipment/Shipments/{id}/Details
function* getOrganizationDetailSaga({ organizationId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(`/1/organization/${organizationId}/details`);
    yield put({
      type: GET_ORGANIZATION_DETAIL_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ORGANIZATION_DETAIL_FAILURE,
      errorMessage: "Unable to get organization details",
    });
  }
}

function* resetOrganizationDetailSaga() {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    yield put({ type: RESET_ORGANIZATION_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_ORGANIZATION_DETAIL_FAILURE,
      errorMessage: "Unable to reset organization details",
    });
  }
}

// api/1/roles/organization/menuresource?organizationId=TestChildOrganizationUnderClie
function* getResourceMenuSaga({ organizationId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data, status } = yield API.get(
      `/1/roles/organization/menuresource?organizationId=${organizationId}`
    );
    if (status === 200)
      yield put({
        type: GET_RESOURCE_MENU_SUCCESS,
        payload: data,
      });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_RESOURCE_MENU_FAILURE,
      errorMessage: "Unable to get roles details",
    });
  }
}

function* getOrganisationResourceSaga({ organizationId, hierarchy }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    let { data, status } = yield API.get(
      hierarchy === 1 || hierarchy === 2
        ? `/1/roles/user-creation/permission?organizationId=${organizationId}&hierarchy=${hierarchy}`
        : `/1/roles/user-creation/permission?organizationId=${organizationId}`
    );

    if (status === 200) {
      const dataPermission = data?.permission
        ?.filter(
          (item) => !item.resourceDisplayName.includes("Work-In-Progress")
        )
        .sort((a, b) =>
          a.resourceDisplayName.localeCompare(b.resourceDisplayName)
        );
      data.permission = dataPermission;
      yield put({
        type: GET_ORG_RESOURCES_SUCCESS,
        payload: data,
      });
    } else if (status === 204)
      yield put({
        type: GET_ORG_RESOURCES_SUCCESS,
        payload: {
          id: "",
          name: "",
          organizationId: "",
          organizationName: "",
          hierarchy: "",
          permission: [],
        },
      });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ORG_RESOURCES_FAILURE,
      errorMessage: "Unable to get resource details",
    });
  }
}

// 1/users/me/emailscenarios?organizationId=DATNET_US'
function* getEmailTypeSaga({ organizationId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data, status } = yield API.get(
      `/1/users/me/emailscenarios?organizationId=${organizationId}`
    );
    if (status === 200)
      yield put({
        type: GET_EMAIL_TYPE_SUCCESS,
        payload: data.value,
      });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_EMAIL_TYPE_FAILURE,
      errorMessage: "Unable to get email types",
    });
  }
}

function* getOrganizationListSaga({ orgPayload }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data, status } = yield API.post(
      `/1/organization/page/records?isPaginationOn=false`,
      orgPayload
    );
    if (status === 200)
      yield put({
        type: GET_ORGANIZATION_LIST_SUCCESS,
        payload: data?.value?.organizationInputOutput,
      });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ORGANIZATION_LIST_FAILURE,
      errorMessage: "Unable to get organization list",
    });
  }
}

// /api/1/user-management/{organizationId}/list/localuser
function* getLocalUserDetailSaga({ organizationId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(
      `/1/user-management/${organizationId}/list/localuser`
    );
    yield put({
      type: GET_LOCAL_USER_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_LOCAL_USER_FAILURE,
      errorMessage: "Unable to get local user details",
    });
  }
}

// /api/1/user-management/organization/{organizationId}/role/{roleId}/list/adminuser
function* getAdminUserDetailSaga({ organizationId, roleId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(
      `/1/user-management/organization/${organizationId}/role/${roleId}/list/adminuser`
    );
    yield put({
      type: GET_ADMIN_USER_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ADMIN_USER_FAILURE,
      errorMessage: "Unable to get admin user details",
    });
  }
}

// api/1/user-management/{organizationId}/admin
function* getUserRoleData({ organizationId }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(
      `/1/user-management/${organizationId}/admin`
    );
    yield put({
      type: GET_USER_ROLE_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_USER_ROLE_LIST_FAILURE,
      errorMessage: "Unable to get user role list",
    });
  }
}

// t/api/1/organization/active/list
function* getActiveOrgData() {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get("1/organization/active/list");
    yield put({
      type: GET_ACTIVE_ORG_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ACTIVE_ORG_LIST_FAILURE,
      errorMessage: "Unable to get active organization list",
    });
  }
}

function* getHistoryLogSaga({ grid, id }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(`/1/${grid}/${id}/history`);
    yield put({
      type: GET_HISTORY_LOG_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_HISTORY_LOG_FAILURE,
      errorMessage: "Unable to get history log data",
    });
  }
}

// api/1/columnformat/{type}
function* getDateFormatData({ id }) {
  try {
    yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data } = yield API.get(`/1/columnformat/${id}`);
    yield put({
      type: GET_DATE_FORMAT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_DATE_FORMAT_FAILURE,
      errorMessage: "Unable to get date format list",
    });
  }
}

function* getEnableUserListSaga({ payload }) {
  try {
    //pi/1/user-management/userlist/resource/permission
    yield put({ type: SET_PERMISSION_LOADING });
    const { data, status } = yield API.post(
      `/1/user-management/userlist/resource/permission`,
      payload
    );
    if (status === 200) {
      yield put({
        type: GET_ENABLE_USER_LIST_SUCCESS,
        payload: data?.value,
      });
    } else {
      yield put({
        type: GET_ENABLE_USER_LIST_SUCCESS,
        payload: "",
      });
    }
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ENABLE_USER_LIST_FAILURE,
      errorMessage: "Unable to get user list",
    });
  }
}

function* getDefaultRolesaga({ organizationId }) {
  try {
    // yield put({ type: SET_EDITORGANISATION_LOADING });
    const { data, status } = yield API.get(
      `/1/roles/organization/${organizationId}/default/role`
    );
    if (status === 200)
      yield put({
        type: GET_DEFAULT_ROLE_SUCCESS,
        payload: data?.value,
      });
    console.log("data.value", data.value);
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_DEFAULT_ROLE_FAILURE,
      errorMessage: "Unable to get default roles",
    });
  }
}

function* EditOrganizationSaga() {
  yield all([
    yield takeEvery(GET_ORGANIZATION_DETAIL, getOrganizationDetailSaga),
  ]);
  yield all([
    yield takeEvery(RESET_ORGANIZATION_DETAIL, resetOrganizationDetailSaga),
  ]);
  yield all([yield takeEvery(GET_ORG_RESOURCES, getOrganisationResourceSaga)]);
  yield all([yield takeEvery(GET_RESOURCE_MENU, getResourceMenuSaga)]);
  yield all([yield takeEvery(GET_EMAIL_TYPE, getEmailTypeSaga)]);
  yield all([yield takeEvery(GET_ORGANIZATION_LIST, getOrganizationListSaga)]);
  yield all([yield takeEvery(GET_LOCAL_USER, getLocalUserDetailSaga)]);
  yield all([yield takeEvery(GET_ADMIN_USER, getAdminUserDetailSaga)]);
  yield all([yield takeEvery(GET_USER_ROLE_LIST, getUserRoleData)]);
  yield all([yield takeEvery(GET_ACTIVE_ORG_LIST, getActiveOrgData)]);
  yield all([yield takeEvery(GET_HISTORY_LOG, getHistoryLogSaga)]);
  yield all([yield takeEvery(GET_DATE_FORMAT, getDateFormatData)]);
  yield all([yield takeEvery(GET_ENABLE_USER_LIST, getEnableUserListSaga)]);
  yield all([yield takeEvery(GET_DEFAULT_ROLE, getDefaultRolesaga)]);
}

export default EditOrganizationSaga;
