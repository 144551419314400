import { makeStyles } from "@mui/styles";

const bookingSettingStyle = makeStyles((theme) => ({
  tableCellLeft: {
    paddingLeft: "50px",
  },
  tableColumnCollapseHead: {
    fontWeight: "bold !important",
  },
  tableRow: {
    "&:nth-of-type(even)": { backgroundColor: "#F7F7F7" },
    "&:nth-of-type(odd)": { backgroundColor: "white" },
    "& .MuiTableCell-root": {
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "40px",
    },
  },
  defaultTableRow: {
    "&:nth-of-type(even)": { backgroundColor: "#F7F7F7", height: "60px" },
    "&:nth-of-type(odd)": { backgroundColor: "white", height: "60px" },
    "& .MuiTableCell-root": {
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "40px",
    },
  },
  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: "10",
    backgroundColor: theme.palette.color.greyS2,
    "& .MuiTableRow-root": {
      backgroundColor: theme.palette.color.greyS2,
      textTransform: "uppercase",
      height: "40px",
    },
    "& .MuiTableSortLabel-root": {
      flexDirection: "row-reverse",
      paddingBottom: "4px",
      color: `${theme.palette.color.white} !important`,
    },
    "& .MuiTableCell-root": {
      backgroundColor: theme.palette.color.dimblack,
      color: `${theme.palette.color.white} !important`,
      ...theme.typography.body22,
      height: "48px",
      paddingLeft: "16px",
      padding: 0,
      width: "50%",
      "& .Mui-active": {
        color: `${theme.palette.color.white} !important`,
        backgroundColor: theme.palette.color.dimblack,
      },
    },
  },
  tableCell: {
    color: theme.palette.color.cyanBlueC1,
    fontSize: "10px",
    textTransform: "capitalize",
    letterSpacing: "0.02px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  iconButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    paddingRight: "10px",
    paddingTop: "5px",
    color: "#183650",
  },
  saveButtonDisabled: {
    width: "93px",
    height: "38px",
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "#CED4DA80 !important",
    font: "normal normal 600 14px/24px",
    letterSpacing: "0px",
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "20px",
    textTransform: "capitalize",
    opacity: 1,
  },
  shippingMode: {
    fontWeight: "600 !important",
  },
  customButton: {
    boxShadow: "1px 1px 2px #0000000D",
    backgroundColor: `${theme.palette.color.white} !important`,
    border: " 0.5px solid #CFD4D9 !important",
    borderRadius: "20px  !important",
    fontWeight: "600",
    letterSpacing: "0.01px",
    textTransform: "capitalize",
    fontSize: "14px !important",
    color: "#6C757D !important",
    padding: "7px 16px !important",
    display: "flex !important",
    justifyContent: "center !important",
    marginRight: "10px !important",
    alignItems: "center !important",
    height: "40px",
    opacity: 1,

    "&:hover": {
      border: "0.5px solid #34CCFF1A !important",
      backgroundColor: "#34CCFF0D !important",
      opacity: 1,
    },

    "&.selected": {
      border: "0.5px solid #1F364E !important",
      backgroundColor: "#1F364E !important",
      color: `${theme.palette.color.white} !important`,
    },
  },
  formLabel: {
    "& .MuiFormControlLabel-label": {
      font: "normal normal 600 14px/19px Open Sans",
      letterSpacing: "0.01px",
      color: "#212529",
      opacity: 1,
    },
    "& svg": {
      width: "18px",
      height: "18px",
      color: "#1F364E",
    },
  },
  shippingIcon: {
    marginRight: "5px",
    marginBottom: "3px",
  },
  loadContainer: {
    display: "flex",
  },
  autoCompleteClass: {
    width: "339px",
    "& .MuiTextField-root": {
      position: "relative",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  uploadIcon: {
    color: theme.palette.color.primary,
    cursor: "pointer",
    marginTop: "4px",
  },
  disabledUploadIcon: {
    color: theme.palette.color.grayS7,
    marginTop: "4px",
  },
  thumb: {
    background: "#183650",
  },
  mark: {
    background: "#CED4DA",
  },
  rail: {
    background: "#CED4DA",
  },
  track: {
    background: "#CED4DA",
  },
  valueLabel: {
    "&>*": {
      background: "#CED4DA",
    },
  },
  summaryInput: {
    border: "1px solid #e6e1e1 !important",
    backgroundColor: theme.palette.color.white,
    outline: "none !important",
    height: "30px",
    fontSize: "16px",
    fontFamily: "'myriad-pro', sans-serif",
    width: "90%",
    // padding: "7px 9px 8px",
    "&:disabled": {
      backgroundColor: "#e6e1e157 !important",
    },
  },
  errorInput: {
    border: "1px solid red !important",
    backgroundColor: theme.palette.color.white,
    outline: "none !important",
    height: "30px",
    fontSize: "16px",
    fontFamily: "'myriad-pro', sans-serif",
    width: "90%",
    "&:disabled": {
      backgroundColor: "#e6e1e157 !important",
    },
  },
  emailDiv: {
    display: "flex",
  },
  emailRowAddButton: {
    color: theme.palette.color.white,
    textTransform: "capitalize",
    ...theme.typography.body22,
  },
  emailAutocomplete: {
    width: "-webkit-fill-available",
  },
  emailDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: "10px",
    marginTop: "10px",
    backgroundColor: "ghostwhite",
    borderRadius: "4px",
    boxShadow:
      "0px 1px 1px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& h4": {
      ...theme.typography.h4,
      color: theme.palette.color.dimblack,
    },
    "& p": {
      ...theme.typography.body22,
      color: theme.palette.color.dimblack,
      "& span": {
        ...theme.typography.body22,
        color: theme.palette.color.black,
      },
    },
  },
}));

export { bookingSettingStyle };
