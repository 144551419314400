import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { DialogContent, DialogContentText, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Loader from "../components/UI/Loader/Loader";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.color.white,
  },
  clearIcon: {
    cursor: "pointer",
    position: "relative",
    color: theme.palette.color.white,
    width: "24px",
    height: "24px",
  },
  dialogActions: {
    alignSelf: "end",
    marginRight: "16px",
  },
  iconButton: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    marginTop: "3px",
    color: theme.palette.color.white,
  },
  cancelButton: {
    ...theme.typography.h6,
    minWidth: "93px",
    height: "38px",
    color: `${theme.palette.color.skyBlue}`,
    background: `${theme.palette.color.white}`,
    border: "none",
    textTransform: "uppercase",
    letterSpacing: "0px",
    marginRight: "10px",
    "&:hover": {
      color: `${theme.palette.color.cyanBlueC1}`,
      background: `${theme.palette.color.white}`,
      opacity: 1,
    },
  },
  assignButton: {
    ...theme.typography.body2,
    minWidth: "84px",
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    border: `0.5px solid ${theme.palette.color.lightGrayishBlueB1}`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
}));

const FrachtDeleteDialogBox = ({
  open,
  handleClose,
  message,
  success,
  performAction,
  isDeleting,
  modalTitle,
  showIcon = true,
  restrictClose = false,
  modalType = "",
  iconSrc = "",
  cancelButton = "No",
  saveButton = "Yes",
}) => {
  const classes = useStyles();

  const backgroundColors = {
    Error: "#CC3629",
    Warning: "#FF9E1B",
    Information: "#5BC2E7",
    Normal: "#183650",
    default: "#183650",
  };

  const renderIcon = () => {
    switch (modalType) {
      case "Error":
        return <ReportIcon className={classes.iconButton} id="reportIcon" />;
      case "Warning":
        return <WarningIcon className={classes.iconButton} id="warningIcon" />;
      case "Information":
        return <InfoIcon className={classes.iconButton} id="infoIcon" />;
      case "Normal":
        return <InfoIcon className={classes.iconButton} id="normalIcon" />;
      case "NewIcon":
        return (
          <img
            src={iconSrc}
            alt={iconSrc}
            id="dialogBoxIcon"
            className={classes.iconButton}
          />
        );
      default:
        return (
          <DeleteForeverIcon className={classes.iconButton} id="deleteIcon" />
        );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={restrictClose ? "" : handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "564px",
            maxHeight: 550,
          },
        }}
        maxWidth="md"
      >
        {isDeleting && <Loader />}
        <DialogTitle
          id="dialog-title"
          style={{
            backgroundColor:
              backgroundColors[modalType] || backgroundColors.default,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ display: "flex" }}>
              {showIcon && renderIcon()}
              <Typography className={classes.heading} variant="h3">
                {modalTitle}
              </Typography>
            </div>
            <ClearIcon className={classes.clearIcon} onClick={handleClose} />
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText gutterBottom>
            {success ? (
              <>
                <Typography variant="body2" style={{ color: "#212529" }}>
                  {message}
                </Typography>
              </>
            ) : (
              "Error Occurred"
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          {success ? (
            <>
              <Button
                className={classes.cancelButton}
                autoFocus
                onClick={handleClose}
              >
                {cancelButton}
              </Button>
              <LoadingButton
                className={classes.assignButton}
                autoFocus
                onClick={() => performAction()}
              >
                {saveButton}
              </LoadingButton>
            </>
          ) : (
            <Button autoFocus variant="outlined" onClick={handleClose}>
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FrachtDeleteDialogBox;
