import { all, put, takeEvery } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_NOTIFICATION_SETTING_DATA,
  GET_NOTIFICATION_SETTING_DATA_FAILURE,
  GET_NOTIFICATION_SETTING_DATA_SUCCESS,
  SET_SETTING_LOADING,
} from "./actionTypes";

function* notificationSettingApi({ id }) {
  try {
    yield put({ type: SET_SETTING_LOADING });
    const { data } = yield API.get(`/1/usersetting/me/organization/${id}`);
    yield put({
      type: GET_NOTIFICATION_SETTING_DATA_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_NOTIFICATION_SETTING_DATA_FAILURE,
      errorMessage: "Unable to get notification setting data",
    });
  }
}

function* SettingPageSaga() {
  yield all([
    yield takeEvery(GET_NOTIFICATION_SETTING_DATA, notificationSettingApi),
  ]);
}

export default SettingPageSaga;
