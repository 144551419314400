import {
  GET_NOTIFICATION_SETTING_DATA_FAILURE,
  GET_NOTIFICATION_SETTING_DATA_SUCCESS,
  RESET_NOTIFICATION_SETTING_DATA,
  SET_SETTING_LOADING,
} from "./actionTypes";

const initialState = {
  settingLoading: false,
  error: false,
  errorMessage: "",
  notificationSettingData: "",
};

const SettingPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTING_LOADING:
      return {
        ...state,
        settingLoading: true,
        error: false,
        errorMessage: "",
      };

    case GET_NOTIFICATION_SETTING_DATA_SUCCESS:
      return {
        ...state,
        settingLoading: false,
        notificationSettingData: action.payload.notificationSettings,
        notificationAxisSettingData: action.payload.axisNotificationSettings,
        notificationFrachtData: action.payload.myFrachtNotificationSettings,
      };
    case GET_NOTIFICATION_SETTING_DATA_FAILURE:
      return {
        ...state,
        settingLoading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case RESET_NOTIFICATION_SETTING_DATA:
      return {
        ...state,
        notificationSettingData: "",
        settingLoading: false,
        error: false,
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default SettingPage;
