import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  SET_LOADING,
  GET_USERS_DETAILS_DATA,
  GET_USERS_DETAILS_DATA_SUCCESS,
  GET_USERS_DETAILS_DATA_FAILURE,
  RESET_USERS_DETAILS,
  RESET_USERS_DETAILS_SUCCESS,
  RESET_USERS_DETAILS_FAILURE,
  GET_ATTRIBUTE_LIST_SUCCESS,
  GET_ATTRIBUTE_LIST_FAILURE,
  GET_ATTRIBUTE_LIST,
} from "./actionTypes";

function* getUserDetailSaga({ id }) {
  // console.log("rows saga", id);
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/user-management/${id}/user`);
    yield put({
      type: GET_USERS_DETAILS_DATA_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_USERS_DETAILS_DATA_FAILURE,
      errorMessage: "Unable to get user details",
    });
  }
}

function* resetUserDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_USERS_DETAILS_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_USERS_DETAILS_FAILURE,
      errorMessage: "Unable to reset user details",
    });
  }
}
function* getAttributeSaga() {
  // api/1/user-management/fracht360attribute
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/user-management/fracht360attribute`);
    yield put({
      type: GET_ATTRIBUTE_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_ATTRIBUTE_LIST_FAILURE,
      errorMessage: "Unable to get attribute list",
    });
  }
}
function* EditUserSaga() {
  yield all([yield takeEvery(GET_USERS_DETAILS_DATA, getUserDetailSaga)]);
  yield all([yield takeEvery(RESET_USERS_DETAILS, resetUserDetailSaga)]);
  yield all([yield takeEvery(GET_ATTRIBUTE_LIST, getAttributeSaga)]);
}

export default EditUserSaga;
