import {
  GET_ANALYTICS_DATA,
  GET_AVG_TRANSIT_TIME,
  GET_COUNTRY_WISE_SHIPMENT_COUNT,
  GET_DELAY_COUNT,
  GET_DELIVERY_COMPLIANCE,
  GET_DELIVERY_PURCHASE_ORDER_COUNT,
  GET_DESTINATION_CONTAINER_COUNT,
  GET_DWELL_TRANSSHIP,
  GET_CONTAINER_RETURN,
  GET_PORT_DWELL,
  GET_FOURTH_PL_DATA,
  GET_MONTHLY_SHIPMENT_VOLUME,
  GET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
  GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
  GET_ODQV1_COUNT,
  GET_ORDER_STATUS_COUNT,
  GET_ORGANIZATION_NAME,
  GET_ORIGIN_CONTAINER_COUNT,
  GET_STATUS_WISE_COUNT,
  GET_WIDGET_OPTIONS,
  GET_YEARLY_SHIPMENT_COUNT,
  SET_FOURTH_PL_DATA,
  SET_SWITCH_SELECTION,
  SET_SWITCH_SELECTION_OVERVIEW,
  SET_WIDGET_OPTIONS,
  UPDATE_CLOCK_DATA,
  UPDATE_WIDGET_LAYOUT,
  GET_MONTHLY_SHIPMENT_OVERVIEW,
} from "./actionTypes";

export const updateWidgetsLayout = (widgetList) => {
  return {
    type: UPDATE_WIDGET_LAYOUT,
    widgetList,
  };
};

export const updateClockData = (clockData) => {
  return {
    type: UPDATE_CLOCK_DATA,
    clockData: clockData,
  };
};

export const getOrganizationName = (parentOrganizationId) => {
  return {
    type: GET_ORGANIZATION_NAME,
    parentOrganizationId: parentOrganizationId,
  };
};

export const getWidgetOptions = (organizationId) => ({
  type: GET_WIDGET_OPTIONS,
  organizationId: organizationId,
});

export const getFourthPLData = (organizationId) => ({
  type: GET_FOURTH_PL_DATA,
  organizationId: organizationId,
});

export const set4PlData = (data) => ({
  type: SET_FOURTH_PL_DATA,
  payload: data,
});

export const getDwellTransship = (organizationId, count) => ({
  type: GET_DWELL_TRANSSHIP,
  organizationId: organizationId,
  count: count,
});

export const getContainerReturn = (organizationId, count) => ({
  type: GET_CONTAINER_RETURN,
  organizationId: organizationId,
  count: count,
});

export const getPortDwell = (organizationId, count) => ({
  type: GET_PORT_DWELL,
  organizationId: organizationId,
  count: count,
});

export const setWidgetOptions = (widgetList) => ({
  type: SET_WIDGET_OPTIONS,
  payload: widgetList,
});
export const getOrderStausCount = (organizationId) => ({
  type: GET_ORDER_STATUS_COUNT,
  organizationId: organizationId,
});
export const getDelayCount = (organizationId) => ({
  type: GET_DELAY_COUNT,
  organizationId: organizationId,
});

export const getDeliveryCompliance = (organizationId) => ({
  type: GET_DELIVERY_COMPLIANCE,
  organizationId: organizationId,
});

export const getODQV1Count = (countryCode) => ({
  type: GET_ODQV1_COUNT,
  countryCode: countryCode,
});

export const getYearlyShipmentCount = (organizationId, year) => ({
  type: GET_YEARLY_SHIPMENT_COUNT,
  organizationId,
  year,
});

export const getCountryShipmentCount = (organizationId, year) => ({
  type: GET_COUNTRY_WISE_SHIPMENT_COUNT,
  organizationId,
  year,
});
export const getDeliveryPurchaseOrder = (organizationId) => ({
  type: GET_DELIVERY_PURCHASE_ORDER_COUNT,
  organizationId: organizationId,
});

export const getMostShippedContainerByOrigin = (organizationId, count) => ({
  type: GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
  organizationId,
  count,
});

export const getMostShippedContainerByDestination = (
  organizationId,
  count
) => ({
  type: GET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
  organizationId,
  count,
});

export const getAvgTransitTime = (organizationId, count, days) => ({
  type: GET_AVG_TRANSIT_TIME,
  organizationId,
  count,
  days,
});

export const getMontlyShipmentVolume = (organizationId, year) => ({
  type: GET_MONTHLY_SHIPMENT_VOLUME,
  organizationId,
  year,
});

export const getMontlyShipmentOverview = (organizationId, year) => ({
  type: GET_MONTHLY_SHIPMENT_OVERVIEW,
  organizationId,
  year,
});

export const setSwitchSelection = (selection) => ({
  type: SET_SWITCH_SELECTION,
  payload: selection,
});

export const setSwitchSelectionOverview = (selection) => ({
  type: SET_SWITCH_SELECTION_OVERVIEW,
  payload: selection,
});

export const getStatusCount = (organizationId) => ({
  type: GET_STATUS_WISE_COUNT,
  organizationId,
});

export const getAnalyticData = (companyName) => ({
  type: GET_ANALYTICS_DATA,
  companyName: companyName,
});

export const getOriginContainerCount = (organizationIds, count) => ({
  type: GET_ORIGIN_CONTAINER_COUNT,
  organizationIds,
  count,
});

export const getDestinationContainerCount = (organizationIds, count) => ({
  type: GET_DESTINATION_CONTAINER_COUNT,
  organizationIds,
  count,
});
