import * as React from "react";
import Grid from "@mui/material/Grid";
import StatusCard from "./widgets/statusCard";
import TotalOrder_blue from "../../assets/images/TotalOrder_blue.svg";
import Delivered_blue from "../../assets/images/Delivered_blue.svg";
import Intransit_blue from "../../assets/images/Intransit_blue.svg";
import Shipped_blue from "../../assets/images/Shipped_blue.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderStausCount } from "../../redux/widgets/action";
import { useEffect } from "react";

export default function StatusCardHolder() {
  const { orderStatusCount } = useSelector((state) => state.WidgetsReducer);
  const { organizationId, isFrachtAxis } = useSelector(
    (state) => state.LoginUser
  );
  const statusCardsData = [
    {
      statusIcon: TotalOrder_blue,
      label: "Total Orders",
      count: -1,
      status: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      type: "totalorders",
      displayLabel: "Total Orders",
    },
    {
      statusIcon: Delivered_blue,
      label: "Delivered",
      count: -1,
      status: ["9"],
      type: "archived",
      displayLabel: "Archived",
    },
    {
      statusIcon: Intransit_blue,
      label: "Loaded",
      count: -1,
      status: ["3"],
      type: "loaded",
      displayLabel: "Loaded",
    },
    {
      statusIcon: Shipped_blue,
      label: "Sailed",
      count: -1,
      status: ["6", "7", "8"],
      type: "sailed",
      displayLabel: "Sailed",
    },
  ];
  const dispatch = useDispatch();
  const [statusData, setStatusData] = React.useState(statusCardsData);
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationId && orderStatusCount === null)
      dispatch(getOrderStausCount(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (orderStatusCount) {
      const updatedData = statusCardsData.map((item) => {
        let count = 0;
        count = orderStatusCount?.find((ele) => item.label === ele.status);
        return {
          ...item,
          count: count?.count,
        };
      });

      setStatusData(updatedData);
    }
  }, [orderStatusCount]);

  const redirectToOrders = (card) => {
    if (card.type === "totalorders") {
      return;
    }
    if (card.type === "archived") {
      const gridId = "POSummaryhistory";
      localStorage.removeItem(`${gridId}-viewName`);
      localStorage.removeItem(`grid-${gridId}`);
      localStorage.removeItem(`grid-${gridId}-colums`);
      localStorage.removeItem(`${gridId}-status-card`);
      navigate(`/containersummary/history/${card.type}`);
    } else {
      const gridId = "POSummarylive";
      localStorage.removeItem(`${gridId}-viewName`);
      localStorage.removeItem(`grid-${gridId}`);
      localStorage.removeItem(`grid-${gridId}-colums`);
      localStorage.removeItem(`${gridId}-status-card`);
      navigate(`/containersummary/live/${card.type}`);
    }
  };

  return (
    <Grid container spacing={2}>
      {statusData?.map((card, index) => (
        <Grid
          item
          xs={6}
          key={index}
          style={{ height: "50%" }}
          onClick={() => {
            redirectToOrders(card);
          }}
        >
          <StatusCard
            statusIcon={card.statusIcon}
            statusText={card.displayLabel}
            count={card.count}
          />
        </Grid>
      ))}
    </Grid>
  );
}
