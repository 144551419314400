import * as React from "react";
import ContainerTracker from "../../../assets/images/containertracker.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import { widgetStyles } from "../widgetStyles";
import { getContainerReturn } from "../../../redux/widgets/action";
import { debounce } from "lodash";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SwitchWithLabel from "../../../commonControls/FrachtCustomSwitch";

export default function ContainerReturnTracker(props) {
  const classes = widgetStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationId } = useSelector((state) => state.LoginUser);
  const { containerReturnData, cardLoadingContainer, containerSliderValue } =
    useSelector((state) => state.WidgetsReducer);
  const [selectedVal, setSelectedVal] = React.useState(containerSliderValue);
  const [vesselCount, setVesselCount] = React.useState(containerReturnData);
  const [toggleValue, setToggleValue] = React.useState("Origin");

  const debouncedDispatch = React.useCallback(
    debounce((organizationId, value) => {
      dispatch(getContainerReturn(organizationId, value));
    }, 1000),
    [dispatch]
  );

  function valuetext(e) {
    setSelectedVal(e.target.value);
    debouncedDispatch(organizationId, e.target.value);
  }

  useEffect(() => {
    if (organizationId && containerReturnData === 0) {
      dispatch(getContainerReturn(organizationId, selectedVal));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setVesselCount(containerReturnData);
  }, [containerReturnData]);

  const handleToggle = (e) => {
    setToggleValue(e.target.checked ? "Destination" : "Origin");
  };

  const redirectToOrders = () => {
    const gridId = "POSummary";
    localStorage.removeItem(`${gridId}-viewName`);
    localStorage.removeItem(`grid-${gridId}`);
    localStorage.removeItem(`grid-${gridId}-colums`);
    localStorage.removeItem(`${gridId}-status-card`);
    navigate(`/containersummary/live/containerreturn`, {
      state: {
        dwellTimeDays: selectedVal,
        toggleValue:
          toggleValue && toggleValue === "Destination" ? false : true,
      },
    });
  };

  return (
    <>
      <Card sx={{ width: "100%", borderRadius: "22px" }}>
        <div>
          <CardHeader
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    margin: "24px 0 -24px 4px",
                  }}
                >
                  <div style={{ minWidth: "180px" }}>
                    <Slider
                      classes={{
                        thumb: classes.thumb,
                        rail: classes.rail,
                        track: classes.track,
                        mark: classes.mark,
                      }}
                      size="small"
                      defaultValue={selectedVal}
                      valueLabelDisplay="auto"
                      step={1}
                      marks={marks}
                      min={1}
                      max={20}
                      onChange={valuetext}
                      data-testid="Slider"
                    />
                  </div>
                  <Typography
                    style={{
                      width: "90px",
                      height: "19px",
                      background: "#ffffff",
                      fontSize: "14px",
                      textAlign: "right",
                      display: "flex",
                      paddingLeft: "4px",
                    }}
                  >
                    <div>{`${selectedVal} days`}</div>
                    <div style={{ paddingLeft: "4px" }}>
                      <Tooltip
                        placement="bottom-start"
                        title="Adjustable tolerance in days."
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </div>
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    paddingTop: "20px",
                  }}
                >
                  <SwitchWithLabel
                    switchColor="BlueViolet"
                    label1="Origin"
                    label2="Destination"
                    defaultChecked={false}
                    onChange={(e) => handleToggle(e)}
                  />
                </div>
              </div>
            }
          />
        </div>
        <Divider />
        <div
          component="div"
          onClick={redirectToOrders}
          sx={{
            cursor: "pointer",
          }}
        >
          <CardContent className={classes.dwellCardContent}>
            <img
              src={ContainerTracker}
              className={classes.statusIcon}
              alt="dwellAtTransshipPortIcon"
            />

            <div className={classes.statusTextContainer}>
              <Typography
                variant="body22"
                className={classes.statusText}
                color="color.primary"
              >
                {`Number of containers gated out for ${selectedVal} days or more`}
              </Typography>
              <Typography
                variant="h2A"
                className={classes.statusCount}
                color="#001737"
              >
                {cardLoadingContainer ? (
                  <CircularProgress />
                ) : toggleValue === "Origin" ? (
                  vesselCount?.Origin
                ) : (
                  vesselCount?.Destination
                )}
              </Typography>
            </div>
          </CardContent>
        </div>
      </Card>
    </>
  );
}

export const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 20,
    label: "20",
  },
];
