import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FracthTextField from "../../commonControls/fracthTextField";
import API from "../../utils/axios";
import FractAutoComplete from "../../commonControls/fractAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import FrachtButtonPanel from "../../commonControls/FrachtButtonPanel";
import {
  clearAnalyticsCardList,
  getActiveOrganization,
  getOrganizationList,
  resetRoleData,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import Toast from "../UI/Toast/Toast";
import AppPermission from "../EditOrganization/appPermission";

const useStyles = makeStyles((theme) => ({
  successIcon: {
    fontSize: "60px !important",
    color: "#183650",
  },
  dialogActions: {
    backgroundColor: "white",
    padding: "15px",
    boxShadow: "0px -3px 6px #00000029",
  },
  mailBtn: {
    backgroundColor: "#183650 !important",
    margin: "10px !important",
    color: "white !important",
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "steelblue !important",
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.color.primary,
    color: theme.palette.color.white,
  },
  clearIcon: {
    cursor: "pointer",
  },
  iconButton: {
    width: "26px",
    height: "26px",
    marginRight: "12px",
    marginTop: "2px",
  },
  editIcon: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    marginTop: "2px",
  },

  cancelButton: {
    ...theme.typography.h6,
    width: "93px",
    height: "38px",
    color: `${theme.palette.color.skyBlue} !important`,
    background: `${theme.palette.color.white} !important`,
    border: "none",
    letterSpacing: "0px",
    marginRight: "10px",
    "&:hover": {
      color: `${theme.palette.color.cyanBlueC1} !important`,
      background: `${theme.palette.color.white} !important`,
      opacity: 1,
    },
  },
  startProcessingButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  header: {
    color: theme.palette.color.primary,
    background: theme.palette.color.greyS2,
    padding: "10px 0 10px 20px",
    borderRadius: "0 0 0 0",
    "& span": {
      color: theme.palette.color.error,
      ...theme.typography.small,
    },
  },
}));
const gridErrorMsg = "(One grid needs to be selected)";

const RolesConfiguration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, organizationId } = useSelector((state) => state.LoginUser);
  const { activeOrgList } = useSelector((state) => state.EditOrganization);
  const {
    id,
    name,
    hierarchy,
    organizationId: editRoleOrganizationId,
    permission: rolesDataFromRedux,
  } = useSelector((state) => state.Role);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    organizationId: "",
    role: {
      permission: [],
    },
  });
  const [roleName, setRoleName] = React.useState("Local Admin");
  const [error, setError] = useState({
    roleName: "",
    organizationId: "",
    permission: [],
  });
  const [gridError, setGridError] = useState(false);

  useEffect(() => {
    activeOrgList.length === 0 && dispatch(getActiveOrganization());
    return () => {
      dispatch(resetRoleData());
      dispatch(clearAnalyticsCardList());
    };
  }, [dispatch, organizationId]);

  const handleAutocompleteChange = (name, newValue) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: newValue ? newValue.organizationId : "",
    }));
    setError((prevError) => ({
      ...prevError,
      [name]: "",
      roleName: "",
    }));
  };

  const selectedOrganization = activeOrgList.find(
    (org) => org.organizationId === fieldValues.organizationId
  );

  const checkDuplicateRoles = async (role, orgId) => {
    if (orgId && role) {
      setDataLoading(true);
      try {
        const { status, data } = await API.get(
          `/1/roles/${role?.trim()}/check?organizationId=${orgId}`
        );
        if (status === 200 && data.value) {
          setError({
            ...error,
            organizationId: "Role already exists for this organizationId",
          });
        }
      } catch (error) {
        handleError(error);
      } finally {
        setDataLoading(false);
      }
    }
  };

  const isValid = () => {
    let isdataValid = true;
    let errorIterator = {
      roleName: "",
      organizationId: "",
      permission: [],
    };
    const subMenuArr = [];
    const mapRes = new Map();
    const { organizationId, role } = fieldValues;
    //check if any role exists
    if (role.permission.length > 0) {
      role.permission.forEach((rol) => {
        if (rol.type === "SubMenu") subMenuArr.push(rol);
        else mapRes.set(rol.parentId, rol);
      });

      //check if atleast one parent role is present
      if (mapRes.size === 0) {
        setGridError(true);
        isdataValid = false;
      } else setGridError(false);

      const filtered = subMenuArr.filter((ele) =>
        mapRes.has(ele.parentId) ? false : true
      );
      if (filtered.length > 0) isdataValid = false;
      errorIterator.permission = filtered;
    }
    //make payload invalid as atleast one Parent role should be present
    else {
      setGridError(true);
      isdataValid = false;
    }

    if (!organizationId.trim() && !id) {
      errorIterator.organizationId = "OrganizationId is required";
      isdataValid = false;
    } else if (
      organizationId &&
      error.organizationId === "Role already exists for this organizationId"
    ) {
      errorIterator.organizationId =
        "Role already exists for this organizationId";
      isdataValid = false;
    }

    setError(errorIterator);
    return isdataValid;
  };

  const normalizePermissions = (permissions) => {
    return permissions.map((permission) => {
      const normalizedPermission = {
        ...permission,
        resourceName: permission.resourceName || permission.name || "",
        resourceDisplayName:
          permission.resourceDisplayName || permission.displayName || "",
      };
      delete normalizedPermission.name;
      delete normalizedPermission.displayName;

      return normalizedPermission;
    });
  };

  const postOrEditData = async (dataToBePosted) => {
    setDataLoading(true);
    const apiSuccess = () => {
      setRoleName("");
      setFieldValues({
        organizationId: "",
        role: {
          permission: [],
        },
      });
      setToastMessage(`Role ${id ? "updated" : "added"} successfully.`);
      setToastType(true);
      dispatch(resetRoleData());
      setTimeout(() => navigate("/usermanagement/roles"), 500);
    };
    //  /api/1/roles?isRoleCreation=true
    try {
      const { status } = id
        ? await API.put(`/1/roles`, dataToBePosted)
        : await API.post(`/1/roles?isRoleCreation=true`, dataToBePosted);
      if (!id && status === 201) apiSuccess();
      if (id && status === 200) apiSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleRoleUser = () => {
    if (isValid()) {
      const { role, ...restFieldValues } = fieldValues;
      const normalizedPermissions = normalizePermissions(role.permission);

      const dataToBePosted = {
        ...restFieldValues,
        hierarchy: id ? hierarchy : 0,
        id: id ? id : null,
        name: roleName?.trim(),
        permission: normalizedPermissions,
      };
      postOrEditData(dataToBePosted);
    }
  };

  useEffect(() => {
    if (id && rolesDataFromRedux.length > 0) {
      setRoleName(name);
      setFieldValues({
        id: id,
        organizationId: editRoleOrganizationId,
        role: {
          permission: rolesDataFromRedux
            ? rolesDataFromRedux.map((per) => {
                rolesDataFromRedux.forEach((res) => {
                  if (res.name === per.resourceName) {
                    per.parentId = res.parentId;
                    per.resourceDisplayName = res.displayName;
                    return;
                  }
                });
                return per;
              })
            : [],
        },
      });
    }
  }, [rolesDataFromRedux, editRoleOrganizationId]);

  const handleCancel = () => {
    navigate("/usermanagement/roles");
    dispatch(resetRoleData());
  };

  const getOptionLabel = (option) => {
    if (option) {
      return `${option?.organizationName} - (${option?.organizationId})`
        ? `${option?.organizationName} - (${option?.organizationId})`
        : "";
    } else return "";
  };

  const handleError = (error) => {
    console.log("Error checking duplicate roles", error);
    setToastMessage(
      "Something went wrong at server. Please contact administrator"
    );
    setDataLoading(false);
    setToastType(false);
  };

  function lastVal(orgId) {
    const res2 = orgId?.split("(")?.pop().slice(0, -1);
    return res2;
  }

  useEffect(() => {
    if (editRoleOrganizationId && user?.isSuperAdmin) {
      const payload = {
        pageSize: 100,
        continuationToken: "",
        columnName: "",
        order: "",
        searchKeyword: "",
        includeInactive: false,
        isSuperAdmin: user?.isSuperAdmin,
        isAdmin: user?.isAdmin,
        organizationId: organizationId,
      };
      dispatch(getOrganizationList(payload));
    }
  }, [organizationId, editRoleOrganizationId]);

  return (
    <div className="commonMargin">
      {dataLoading && <Loader />}
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
      <Box sx={{ flexGrow: 1, marginBottom: 3 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <FractAutoComplete
              id="organizationId"
              label="Organization"
              name="organizationId"
              options={activeOrgList || []}
              getOptionLabel={(option) => getOptionLabel(option)}
              onChange={(e, v) => handleAutocompleteChange("organizationId", v)}
              onBlur={(event) => {
                let orgId = event.target.value;
                if (orgId && roleName) {
                  orgId = lastVal(orgId);
                  if (roleName) {
                    checkDuplicateRoles(roleName, orgId);
                  }
                }
              }}
              selectedValue={selectedOrganization || null}
              error={error.organizationId}
              required={true}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0px",
                      fontWeight: "400",
                    }}
                  >
                    {option?.organizationName} - ({option?.organizationId})
                  </p>
                </Box>
              )}
              disabled={id ? true : false}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FracthTextField
              required
              name="name"
              id="name"
              label="Role Name"
              value={roleName}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid item md={12} xs={12}>
        <Box
          style={{
            margin: "20px 0",
            overflow: "hidden",
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
          }}
        >
          <Typography className={classes.header} variant="h4">
            Module Access
            <span>{gridError && `${gridErrorMsg}`}</span>
          </Typography>
          <AppPermission
            rolePermissions={rolesDataFromRedux}
            editRoleId={id}
            roleHierarchy={hierarchy}
            isEdit={id && true}
            showSetting={false}
            userOrganizationId={editRoleOrganizationId}
            callDefaultAPI={false}
            setOrgInfo={setFieldValues}
          />
        </Box>
      </Grid>

      <FrachtButtonPanel
        firstButtonText="Cancel"
        firstHandler={() => {
          handleCancel();
        }}
        secondButtonText={id ? "Update Role" : "Add Role"}
        secondHandler={() => {
          handleRoleUser();
        }}
        disabled={id}
      />
    </div>
  );
};

export default RolesConfiguration;
