import {
  GET_ORGANIZATION_DETAIL,
  RESET_ORGANIZATION_DETAIL,
  GET_ORG_RESOURCES,
  GET_RESOURCE_MENU,
  GET_EMAIL_TYPE,
  GET_ORGANIZATION_LIST,
  ADD_CARD_DATA,
  CLEAR_ANALYTICS_CARD_LIST,
  CLEAR_RESOURCE_MENU,
  SET_EMAIL_DATA,
  UPDATE_EMAIL_DATA,
  GET_LOCAL_USER,
  GET_ADMIN_USER,
  GET_USER_ROLE_LIST,
  GET_ACTIVE_ORG_LIST,
  GET_HISTORY_LOG,
  GET_DATE_FORMAT,
  GET_ENABLE_USER_LIST,
  RESET_ENABLE_USER,
  GET_DEFAULT_ROLE,
} from "./actionTypes";

export const getOrganizationDetail = (organizationId) => ({
  type: GET_ORGANIZATION_DETAIL,
  organizationId: organizationId,
});

export const resetOrganizationDetail = () => ({
  type: RESET_ORGANIZATION_DETAIL,
});

export const getOrganisationResources = (organizationId, hierarchy) => ({
  type: GET_ORG_RESOURCES,
  organizationId: organizationId,
  hierarchy: hierarchy,
});

export const getResourceMenu = (organizationId) => ({
  type: GET_RESOURCE_MENU,
  organizationId: organizationId,
});

export const clearResourceMenu = () => ({
  type: CLEAR_RESOURCE_MENU,
});

export const getEmailType = (organizationId) => ({
  type: GET_EMAIL_TYPE,
  organizationId: organizationId,
});

export const setEmailData = (emailData) => ({
  type: SET_EMAIL_DATA,
  payload: emailData,
});

export const updateEmailData = (emailData) => ({
  type: UPDATE_EMAIL_DATA,
  payload: emailData,
});

export const getOrganizationList = (payload) => ({
  type: GET_ORGANIZATION_LIST,
  orgPayload: payload,
});

export const getActiveOrganization = () => ({
  type: GET_ACTIVE_ORG_LIST,
});

export const addCardData = (analyticsCardData) => ({
  type: ADD_CARD_DATA,
  payload: analyticsCardData,
});

export const clearAnalyticsCardList = () => ({
  type: CLEAR_ANALYTICS_CARD_LIST,
});

export const getLocalUser = (organizationId) => ({
  type: GET_LOCAL_USER,
  organizationId: organizationId,
});

export const getAdminUser = (organizationId, roleId) => ({
  type: GET_ADMIN_USER,
  organizationId: organizationId,
  roleId: roleId,
});

export const getUserRoleList = (organizationId) => ({
  type: GET_USER_ROLE_LIST,
  organizationId: organizationId,
});

export const getHistoryLog = (grid, id) => ({
  type: GET_HISTORY_LOG,
  grid: grid,
  id: id,
});

export const getDateFormat = (id) => ({
  type: GET_DATE_FORMAT,
  id: id,
});

export const getEnableUserList = (payload) => ({
  type: GET_ENABLE_USER_LIST,
  payload: payload,
});

export const resetEnablePermission = () => {
  return {
    type: RESET_ENABLE_USER,
  };
};
export const getDefaultRole = (organizationId) => ({
  type: GET_DEFAULT_ROLE,
  organizationId: organizationId,
});